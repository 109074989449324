<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper clearfix">
      <app-breadcrumb :breadcrumbs="breadcrumbs"/>
      <div class="content-detached content-right">
        <div class="content-wrapper">
          <div class="content-body">
            <div style="height: inherit">
              <section id="ecommerce-header">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="ecommerce-header-items">
                      <div class="result-toggler">
                        <feather-icon
                            icon="MenuIcon"
                            class="d-block d-lg-none"
                            size="21"
                            @click="mqShallShowLeftSidebar = true"
                        />
                        <div class="search-results">
                          Всего найдено {{ products.total }}
                        </div>
                      </div>
                      <div class="view-options d-flex">
                        <feather-icon
                            icon="FileTextIcon"
                            size="21"
                            style="color: #28c76f"
                            v-b-tooltip.hover.top="'Экспорт XLS'"
                            class="align-self-center mr-1 cursor-pointer"
                            @click="exportXls"
                        />
                        <b-dropdown
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            :text="sortBy.text"
                            right
                            variant="outline-primary"
                        >
                          <b-dropdown-item
                              v-for="sortOption in sortByOptions"
                              :key="sortOption.value"
                              @click="sortByType(sortOption)"
                          >
                            {{ sortOption.text }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <!-- Overlay -->
              <div class="body-content-overlay"/>

              <!-- Searchbar -->
              <div class="ecommerce-searchbar mt-1">
                <div class="row">
                  <div class="col-12">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                          v-model="filters.search"
                          placeholder="Поиск товара"
                          class="search-product"
                          @keyup.enter="search"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            icon="SearchIcon"
                            class="text-muted"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
              </div>
              <div class="col-12 text-center mt-2" v-if="loadFilters">
                <spinner-border/>
              </div>
              <!-- Pagination -->
              <section v-if="!loadFilters" class="mt-3">
                <div class="row">
                  <div class="col-12">
                    <b-pagination
                        v-model="currentPage"
                        @input="changePage"
                        :total-rows="products.total"
                        :per-page="products.per_page"
                        first-number
                        align="center"
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18"/>
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18"/>
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </section>
              <!-- Products -->
              <section class="grid-view">
                <div v-for="product in products.data" :key="product.id" class="card p-0 ecommerce-card">
                  <h6 class="item-name ml-1">
                    <router-link class="text-body" :to="{ name: 'product-details', params: { id: product.id } }">
                      {{ product.name }} {{ product.brand_name }}
                    </router-link>
                  </h6>
                  <div class="row">
                    <div class="col-6">
                      <div class="item-img text-center">
                        <span v-if="product.discount > 0" class="discount-block">-{{
                            product.discount | toPretty(0)
                          }}%</span>
                        <router-link :to="{ name: 'product-details', params: { id: product.id } }">
                          <img
                              v-if="product.small_main_image"
                              :alt="`${product.name}-${product.id}`"
                              class="img-fluid"
                              :src="`img/product/${product.small_main_image}`"
                          />
                        </router-link>
                      </div>
                    </div>
                    <div class="col-6 pl-0 pr-2 pt-2">
                      <div class="item-wrapper small">
                        <div class="item-rating">Артикул</div>
                        <div>
                          <small>{{ product.sku }}</small>
                        </div>
                      </div>
                      <div class="item-wrapper small">
                        <div class="item-rating">Цвет</div>
                        <div>
                          <small>{{ product.color_name }}</small>
                        </div>
                      </div>
                      <div class="item-wrapper small mb-1">
                        <div class="item-rating">Pозничная цена</div>
                        <div>
                          <small>{{ product.retail_discount_price }} грн</small>
                        </div>
                      </div>
                      <div class="item-wrapper mt-2 small">
                        <div class="item-rating">Цена</div>
                        <div>
                          <h6>{{ product.user_discount_price }} грн</h6>
                        </div>
                      </div>
                      <div class="item-wrapper small" v-if="product.sizes">
                        <div class="item-rating">Размеры</div>
                        <div>
                          <small> <b>{{ product.sizes }}</b></small>
                        </div>
                      </div>
                      <b-card-text class="item-description">{{ product.description }}</b-card-text>
                    </div>
                  </div>
                  <div class="item-options text-center">
                    <router-link
                        class="btn btn-light btn-wishlist"
                        :to="{name: 'product-details', params: { id: product.id } }"
                    >
                      <feather-icon icon="EyeIcon" class="mr-50"/>
                      <span>Детали</span>
                    </router-link>
                    <b-button
                        variant="primary"
                        @click="showModal(product, product.id)"
                        class="btn-cart"
                    >
                      <feather-icon icon="ShoppingCartIcon" class="mr-50"/>
                      <span>В корзину</span>
                    </b-button>
                  </div>
                </div>
              </section>

              <!-- Pagination -->
              <section v-if="!loadFilters">
                <div class="row">
                  <div class="col-12">
                    <b-pagination
                        v-model="currentPage"
                        @input="changePage"
                        :total-rows="products.total"
                        :per-page="products.per_page"
                        first-number
                        align="center"
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18"/>
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18"/>
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </section>

              <!-- modal vertical center -->
              <b-modal
                  size="lg"
                  ref="add-cart"
                  centered
                  hide-footer
              >
                <template slot="modal-title">
                  <h3>Добавление в корзину:</h3>
                </template>
                <div class="card-text">
                  <div class="row">
                    <div class="col-12">
                      {{ selectedProduct.name }} {{ selectedProduct.brand_name }}
                    </div>
                    <div class="col-12 mt-1">
                      Артикул <b>{{ selectedProduct.sku }}</b>
                    </div>
                  </div>
                  <ProductStockForm v-for="stock in selectedProduct.specifications" :info="stock" :key="stock.id"/>
                </div>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-detached sidebar-left">
        <div class="sidebar">
          <div class="sidebar-shop" :class="{'show': mqShallShowLeftSidebar}">
            <div class="row">
              <div class="col-12">
                <h6 class="filter-heading d-none d-lg-block">Фильтры</h6>
              </div>
            </div>
            <div class="card px-1 pb-1">
              <div class="row pt-1">
                <div class="col-12">
                  <div class="btn btn-sm btn-outline-success" v-if="filters.categoryName"
                       @click="deleteFilter('category')">
                    {{ filters.categoryName }}
                    <feather-icon icon="XIcon" class="ml-50"/>
                  </div>
                  <div class="btn btn-sm btn-outline-success" v-if="filters.brandName" @click="deleteFilter('brand')">
                    Бренд: {{ filters.brandName }}
                    <feather-icon icon="XIcon" class="ml-50"/>
                  </div>
                  <div class="btn btn-sm btn-outline-success" v-if="filters.colorName" @click="deleteFilter('color')">
                    Цвет: {{ filters.colorName }}
                    <feather-icon icon="XIcon" class="ml-50"/>
                  </div>
                  <div class="btn btn-sm btn-outline-success" v-if="filters.size" @click="deleteFilter('size')">
                    Размер: {{ filters.size }}
                    <feather-icon icon="XIcon" class="ml-50"/>
                  </div>
                </div>
              </div>
              <app-collapse accordion type="margin">
                <app-collapse-item title="Категории">
                  <b-form-radio-group
                      v-if="!loadFilters"
                      v-model="filters.categories"
                      class="categories-radio-group"
                      stacked
                      value-field="id"
                      text-field="name"
                      @change="filterCategory"
                      :options="filterOptions.categories"
                  />
                  <div class="loading-row col-12 text-center" v-else>
                    <spinner-border/>
                  </div>
                </app-collapse-item>

                <app-collapse-item title="Бренды">
                  <b-form-radio-group
                      v-if="!loadFilters"
                      v-model="filters.brand"
                      class="brands-radio-group"
                      stacked
                      value-field="id"
                      text-field="name"
                      @change="filterBrand"
                      :options="filterOptions.brands"
                  />
                  <div class="col-12 loading-row text-center" v-else>
                    <spinner-border/>
                  </div>
                </app-collapse-item>

                <app-collapse-item title="Размеры">
                  <b-form-radio-group
                      v-if="!loadFilters"
                      v-model="filters.size"
                      class="brands-radio-group"
                      stacked
                      @change="filterSize"
                      :options="filterOptions.sizes"
                  />
                  <div class="col-12 loading-row text-center" v-else>
                    <spinner-border/>
                  </div>
                </app-collapse-item>

                <app-collapse-item title="Цвета">
                  <b-form-radio-group
                      v-if="!loadFilters"
                      v-model="filters.color"
                      class="brands-radio-group"
                      stacked
                      value-field="id"
                      text-field="name"
                      @change="filterColor"
                      :options="filterOptions.colors"
                  />
                  <div class="col-12 loading-row text-center" v-else>
                    <spinner-border/>
                  </div>
                </app-collapse-item>
              </app-collapse>
            </div>
          </div>
        </div>

        <div class="body-content-overlay"
             :class="{'show': mqShallShowLeftSidebar}"
             @click="mqShallShowLeftSidebar = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormRadioGroup, BInputGroup, BInputGroupAppend, BFormInput, BCardText, BButton, BPagination, BDropdown,
  BDropdownItem, VBTooltip,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import SpinnerBorder from '@/views/components/spinner/SpinnerBorder.vue';
import ProductStockForm from '@/views/components/ProductStockForm.vue';

export default {
  name: 'Remains',
  directives: { Ripple, 'b-tooltip': VBTooltip },
  components: {
    // BSV
    BFormRadioGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCardText,
    BButton,
    BPagination,
    SpinnerBorder,
    ProductStockForm,
    AppBreadcrumb,
    BDropdown,
    BDropdownItem,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      loading: true,
      loadFilters: true,
      currentPage: 1,
      breadcrumbs: [],
      products: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: 0,
        per_page: 10,
      },
      filters: {
        categories: null,
        categoryName: null,
        priceRange: null,
        brand: null,
        brandName: null,
        size: null,
        color: null,
        colorName: null,
        search: null,
      },
      selectedProduct: {
        name: null,
        sku: null,
        brand_name: null,
        specifications: [],
      },
      filterOptions: {
        categories: [],
        brands: [],
        sizes: [],
        colors: [],
      },
      sortByOptions: [
        { text: 'Посл поступление', value: 'featured' },
        { text: 'Макс Скидка', value: 'discount-desc' },
      ],
      sortBy: { text: 'Посл поступление', value: 'featured' },
      mqShallShowLeftSidebar: false,
    };
  },
  async mounted() {
    await this.initFilters();
    await this.getProducts();

    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page;
    }
    await this.getFilterOptions();
  },
  methods: {
    async initFilters() {
      const filters = this.$route.query;
      this.filters = (await this.$api.products.filters({
        ...(filters.category_id && { category_id: filters.category_id }),
        ...(filters.brand_id && { brand_id: filters.brand_id }),
        ...(filters.color_id && { color_id: filters.color_id }),
      })).data;

      if (filters) {
        this.filters.categories = filters.category_id || null;
        this.filters.brand = filters.brand_id || null;
        this.filters.color = filters.color_id || null;
        this.filters.size = filters.size || null;
        this.filters.search = filters.search || null;

        if (filters.sort) {
          this.sortBy = this.sortByOptions.find((option) => option.value === filters.sort);
        }
      }
    },
    async deleteFilter(filterType) {
      switch (filterType) {
        case 'category':
          this.filters.categories = null;
          await this.filterCategory();
          break;
        case 'brand':
          this.filters.brand = null;
          await this.filterBrand();
          break;
        case 'color':
          this.filters.color = null;
          await this.filterColor();
          break;
        case 'size':
          this.filters.size = null;
          await this.filterSize();
          break;
        default:
          break;
      }
    },
    async filterCategory() {
      this.loadFilters = true;
      this.filters.categoryName = this.filters.categories
          ? this.filterOptions.categories.find((category) => category.id === parseInt(this.filters.categories, 10)).name
          : null;
      this.filters.colorName = this.filters.color
          ? this.filterOptions.colors.find((color) => color.id === parseInt(this.filters.color, 10)).name
          : null;

      this.filters.brandName = this.filters.brand && this.filterOptions.brands.length
          ? this.filterOptions.brands.find((brand) => brand.id === parseInt(this.filters.brand, 10)).name
          : null;
      const response = (await this.$api.products.filterOptions({
        ...(this.filters.categories && { category_id: this.filters.categories }),
      })).data;
      this.filterOptions.categories = response.categories;
      this.filterOptions.brands = response.brands;
      this.filterOptions.sizes = response.sizes;
      this.filterOptions.colors = response.colors;
      const query = { ...this.$route.query };

      if (this.filters.categories === null) {
        delete query.category_id;
      } else {
        query.category_id = this.filters.categories;
      }
      this.currentPage = 1;
      delete query.page;
      await this.$router.replace({ query }).catch(() => {
      });
      await this.getProducts();
      this.loadFilters = false;
    },
    async filterBrand() {
      this.loadFilters = true;
      this.filters.brandName = this.filters.brand
          ? this.filterOptions.brands.find((brand) => brand.id === parseInt(this.filters.brand, 10)).name
          : null;
      const query = { ...this.$route.query };
      if (this.filters.brand === null) {
        delete query.brand_id;
      } else {
        query.brand_id = this.filters.brand;
      }

      await this.$router.replace({ query }).catch(() => {
      });
      await this.getProducts();
      this.loadFilters = false;
    },
    async search() {
      this.loadFilters = true;
      const query = { ...this.$route.query };
      if (this.filters.search === null || this.filters.search === '') {
        delete query.search;
      } else {
        query.search = this.filters.search;
      }

      await this.$router.replace({ query }).catch(() => {
      });
      await this.getProducts();
      this.loadFilters = false;
    },
    async changePage(page) {
      this.products.current_page = page;
      this.loadFilters = true;
      const query = { ...this.$route.query };

      if (page > 1) {
        query.page = page;
      } else {
        delete query.page;
      }

      await this.$router.replace({ query }).catch(() => {});

      await this.getProducts();
      this.loadFilters = false;
    },
    async sortByType(type) {
      this.loadFilters = true;
      this.sortBy = type;
      const query = { ...this.$route.query };
      if (this.sortBy.value === 'featured') {
        delete query.sort;
      } else {
        query.sort = this.sortBy.value;
      }

      await this.$router.replace({ query }).catch(() => {
      });
      await this.getProducts();
      this.loadFilters = false;
    },
    async filterColor() {
      this.loadFilters = true;
      this.filters.colorName = this.filters.color
          ? this.filterOptions.colors.find((brand) => brand.id === parseInt(this.filters.color, 10)).name
          : null;
      const query = { ...this.$route.query };
      if (this.filters.color === null) {
        delete query.color_id;
      } else {
        query.color_id = this.filters.color;
      }

      await this.$router.replace({ query }).catch(() => {
      });
      await this.getProducts();
      this.loadFilters = false;
    },
    async filterSize() {
      this.loadFilters = true;
      const query = { ...this.$route.query };
      if (this.filters.size === null) {
        delete query.size;
      } else {
        query.size = this.filters.size;
      }

      await this.$router.replace({ query }).catch(() => {
      });
      await this.getProducts();
      this.loadFilters = false;
    },
    async getProducts() {
      this.loading = true;
      const filters = { ...this.$route.query };
      filters.limit = 30;

      this.products = (await this.$api.products.remains(filters)).data;
      this.loading = false;
    },
    async exportXls() {
      try {
        await this.$api.products.exportRemains('remains.xls');
      } catch (e) {
        // console.log(e.message);
      }
    },
    async getFilterOptions() {
      this.loadFilters = true;
      const response = (await this.$api.products.filterOptions({
        ...(this.filters.categories && { category_id: this.filters.categories }),
      })).data;

      this.filterOptions.categories = response.categories;
      this.filterOptions.brands = response.brands;
      this.filterOptions.sizes = response.sizes;
      this.filterOptions.colors = response.colors;
      this.loadFilters = false;
    },
    async showModal(product, id) {
      this.selectedProduct.name = product.name;
      this.selectedProduct.sku = product.sku;
      this.selectedProduct.brand_name = product.brand_name;
      this.selectedProduct.price = product.user_price;
      const stockInfo = await this.$api.products.getStockInfo(id);
      this.selectedProduct.specifications = stockInfo.data;

      if (this.selectedProduct.specifications.length === 1 && this.selectedProduct.specifications[0].qty === '1') {
        try {
          await this.$api.cart.add(this.selectedProduct.specifications[0].id, {
            qty: 1, price: this.selectedProduct.specifications[0].prices.user_discount_price,
          });
          this.$bvToast.toast('Товар добавлен в корзину', {
            title: 'Выполнено успешно',
            variant: 'success',
            solid: true,
          });
          await this.getProducts();
        } catch (e) {
          // console.log(e);
        }
      } else {
        this.$refs['add-cart'].show();
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.ecommerce-header-items {
  min-height: 40px;
}

.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  height: 300px;
  overflow-y: scroll;

  ::v-deep {
    .custom-radio, .custom-control-label {
      cursor: pointer;
    }

    .custom-control {
      margin-bottom: 0.75rem;
    }
  }
}

.rrp, .item-rating.rrp {
  color: lightgray;
}

.discount-block {
  position: absolute;
  top: 10px;
  left: 18px;
  color: red;
  font-weight: bold;
  font-size: 18px;
}

.btn-sm {
  margin-bottom: 0.5rem;
}
</style>
