<template>
  <b-spinner label="Loading..." />
</template>

<script>
import { BSpinner } from 'bootstrap-vue';

export default {
  components: {
    BSpinner,
  },
};
</script>
